import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ContentBox from "../components/content-box"
import PageHeader from "../components/headers/page-header"
import SectionHeader from "../components/headers/section-header"

const Categories = ({ pageContext, data }) => {
  const { category } = pageContext
  const { edges } = data.allMarkdownRemark
  const categoryHeader = `${category}`

  return (
    <Layout>
      <SEO
        title={categoryHeader}
        description={`Comic, movie, documentary and book reviews related to politics, obscure pop-culture, Conspiracy Theories and the Occult.`}
      />
      <PageHeader><Link to={'/blog'} title={'Blog'}>Blog</Link></PageHeader>
      <ContentBox>
        <SectionHeader align={'left'}>{categoryHeader}</SectionHeader>
        <ul>
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <li key={slug}>
                <Link to={slug} title={title||slug}>{title||slug}</Link>
              </li>
            )
          })}
        </ul>
        {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
        <Link to="/categories" title={'All categories'}>All categories</Link>
      </ContentBox>
    </Layout>
  )
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Categories

export const pageQuery = graphql`
    query($category: String) {
        allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { categories: { in: [$category] } } }
        ) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        type
                    }
                }
            }
        }
    }
`